import { gql } from '@apollo/client';

export const GQL_PROVINCES_PUBLIC = gql`
  query getProvincesByKiriminAja {
    getProvincesByKiriminAja {
      id
      name
    }
  }
`;

export const GQL_DISTRICTS_BY_ID_PUBLIC = gql`
  query getDistrictsByIdPublic($id: Int) {
    getDistrictsByIdPublic(id: $id) {
      id
      name
      province_id
      province_name
    }
  }
`;

export const GQL_CITIES_PUBLIC = gql`
  query getCitesPublic {
    getCitesPublic {
      id
      name
      province_id
      province_name
    }
  }
`;

export const GQL_DISTRICTS_PUBLIC = gql`
  query getDistrictsPublic($id: Int) {
    getDistrictsPublic(id: $id) {
      id
      name
      alias
      postcode
    }
  }
`;

export const GQL_SUB_DISTRICTS_PUBLIC = gql`
  query getSubDistictKiriminAjabyId($districtId: Int) {
    getSubDistictKiriminAjabyId(districtId: $districtId) {
      id
      subDistrictName
      districtId
      postcode
    }
  }
`;

export const GQL_AREAS_PUBLIC = gql`
  query getAreasPublic($id: Int) {
    getAreasPublic(id: $id) {
      id
      name
      alias
      postcode
    }
  }
`;

export const GQL_INSERT_ADDRESS = gql`
  mutation insertAddressWithValidation($input: AddressInput!) {
    insertAddressWithValidation(input: $input) {
      address
      area
      id
      createdAt
      district
      mobile
      name
      notes
      postcode
      province
      subdistrict
      updatedAt
      userId
      errorType
    }
  }
`;

export const GQL_UPDATE_ADDRESS = gql`
  mutation editAddressWithValidation($input: AddressInput!) {
    editAddressWithValidation(input: $input) {
      id
      address
      area
      createdAt
      district
      mobile
      name
      notes
      postcode
      province
      subdistrict
      updatedAt
      userId
      errorType
    }
  }
`;

export const GQL_GET_USER_ADDRESS = gql`
  query getUserAddress {
    getUserAddress {
      id
      name
      mobile
      notes
      address
      postcode
      province
      district
      subdistrict
      area
      area_id
      suburb_id
      sub_district_id
      city_id
      province_id
    }
  }
`;
