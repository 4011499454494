import { gql } from '@apollo/client';

export const GQL_UPDATE_TELEGRAM_CHAT_ID = gql`
  mutation updateTelegramChatId($input: UserInput) {
    updateTelegramChatId(input: $input) {
      id
      telegramChatId
      isTelegramLinked
      linkToken
      errorMessage
    }
  }
`;

export const TEST_TELEGRAM_NOTIFICATION = gql`
    mutation testSendingTelegramNotification($input: UserInput) {
        testSendingTelegramNotification(input: $input) {
            telegramChatId
        }
    }
`;

