import { gql } from '@apollo/client';

export const GQL_GET_COURSE = gql`
  query getPaymentLinkFilteredList(
    $search: PaymentLinkSearchInput
    $sortField: PaymentLinkSortField
    $sortDirection: SortDirection
  ) {
    getPaymentLinkFilteredList(search: $search, sortField: $sortField, sortDirection: $sortDirection) {
      id
      amount
      crossoutPrice
      category
      createdAt
      startAt
      expiredAt
      limit
      paymentType
      urlParamTracking
      multipleImage {
        url
        updatedAt
      }
      form {
        fieldObject
        id
        name
      }
      certificate {
        id
        layout
        name
        templateImageId
        startAt
        isViewAllModulesRequired
        templateImage {
          id
          url
          title
        }
      }
      courseSections {
        description
        id
        name
        position
        link
        status
        courseModules {
          id
          name
          type
          timerInMinutes
          position
          status
          link
          coachingUrl
          description
          embedUrl
          isUnskipable
          isPreview
          isVisible
          isContentNotProtected
          isRetryDisabled
          quiz
          startAt
          endAt
          width
          height
          content {
            files {
              id
              filename
              size
              mimetype
              createdAt
            }
            file {
              id
              filename
              size
              mimetype
              createdAt
              updatedAt
              bunnyVideoId
            }
            id
            createdAt
          }
        }
      }
      description
      notes
      type
      reminderWellcomeId
      status
      redirectUrl
      name
      link
      id
      isViewAllModulesInOrderRequired
      gradeThreshold
      coverImage {
        url
      }
      user {
        id
        bunnyLibraryId
        account {
          id
          name
        }
      }
      checkoutSettings {
        id
        buttonName
        buttonNote
        discountButton
        couponOption
        paymentLinkId
        termOption
        termUrl
        recommendedAmount
        isPopupRecentTransaction
      }
    }
  }
`;

export const GQL_INSERT_COURSE_SECTION = gql`
  mutation insertCourseSectionBySlug($input: CourseSectionInput!) {
    insertCourseSectionBySlug(input: $input) {
      createdAt
      description
      id
      name
      link
      status
      paymentLinkId
      position
      updatedAt
      userId
      isPublicPreview
      isBlurCoverImage
      coverImage {
        id
        url
      }
      membershipTierContents {
        courseSectionId
        membershipTierId
        id
        status
      }
      courseModules {
        id
        courseSectionId
        coachingUrl
        content {
          files {
            id
            filename
            size
            mimetype
            createdAt
          }
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
          createdAt
        }
        createdAt
        description
        embedUrl
        isPreview
        isVisible
        isContentNotProtected
        link
        status
        name
        paymentLinkId
        position
        type
        updatedAt
        userId
        quiz
        startAt
        endAt
      }
    }
  }
`;

export const GQL_EDIT_COURSE_SECTION = gql`
  mutation editCourseSectionBySlug($input: CourseSectionInput!) {
    editCourseSectionBySlug(input: $input) {
      createdAt
      description
      id
      name
      link
      status
      paymentLinkId
      position
      updatedAt
      userId
      isPublicPreview
      isBlurCoverImage
      coverImage {
        id
        url
      }
      membershipTierContents {
        courseSectionId
        membershipTierId
        id
        status
      }
      courseModules {
        id
        courseSectionId
        coachingUrl
        content {
          files {
            id
            filename
            size
            mimetype
            createdAt
          }
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
          createdAt
        }
        createdAt
        description
        embedUrl
        isPreview
        isContentNotProtected
        isVisible
        link
        status
        name
        paymentLinkId
        position
        type
        updatedAt
        userId
        quiz
        startAt
        endAt
      }
    }
  }
`;

export const GQL_REORDER_COURSE_SECTION = gql`
  mutation reorderCourseSection($input: ReorderCourseInput) {
    reorderCourseSection(input: $input) {
      createdAt
      description
      id
      link
      status
      name
      paymentLinkId
      position
      updatedAt
      userId
      courseModules {
        id
        courseSectionId
        coachingUrl
        content {
          files {
            id
            filename
            size
            mimetype
            createdAt
          }
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
          createdAt
        }
        createdAt
        description
        embedUrl
        isPreview
        isContentNotProtected
        isVisible
        link
        status
        name
        paymentLinkId
        position
        type
        updatedAt
        userId
        quiz
        startAt
        endAt
      }
    }
  }
`;

export const GQL_DELETE_AND_REORDER_COURSE_SECTION = gql`
  mutation deleteAndReorderCourseSection($id: ID, $paymentLinkId: ID) {
    deleteAndReorderCourseSection(id: $id, paymentLinkId: $paymentLinkId) {
      createdAt
      description
      id
      link
      status
      name
      paymentLinkId
      position
      updatedAt
      userId
      courseModules {
        id
        courseSectionId
        coachingUrl
        content {
          files {
            id
            filename
            size
            mimetype
            createdAt
          }
          file {
            id
            filename
            size
            mimetype
            createdAt
          }
          id
          createdAt
        }
        createdAt
        description
        embedUrl
        isPreview
        isContentNotProtected
        isVisible
        link
        status
        name
        paymentLinkId
        position
        type
        updatedAt
        userId
        quiz
        startAt
        endAt
      }
    }
  }
`;

export const GQL_INSERT_COURSE_MODULE = gql`
  mutation insertCourseModuleBySlug($input: StrictCourseModuleInput!) {
    insertCourseModuleBySlug(input: $input) {
      id
      courseSectionId
      coachingUrl
      content {
        files {
          id
          filename
          size
          mimetype
          createdAt
        }
        file {
          id
          filename
          size
          mimetype
          createdAt
        }
        id
        createdAt
      }
      createdAt
      description
      embedUrl
      isUnskipable
      isPreview
      isContentNotProtected
      isVisible
      isRetryDisabled
      link
      status
      name
      paymentLinkId
      position
      type
      updatedAt
      userId
      quiz
      startAt
      endAt
      width
      height
    }
  }
`;

export const GQL_EDIT_COURSE_MODULE = gql`
  mutation editCourseModuleBySlug($input: StrictCourseModuleInput!) {
    editCourseModuleBySlug(input: $input) {
      id
      courseSectionId
      coachingUrl
      content {
        files {
          id
          filename
          size
          mimetype
          createdAt
        }
        file {
          id
          filename
          size
          mimetype
          createdAt
        }
        id
        createdAt
      }
      createdAt
      description
      embedUrl
      isUnskipable
      isPreview
      isContentNotProtected
      isVisible
      isRetryDisabled
      link
      status
      name
      paymentLinkId
      position
      timerInMinutes
      type
      updatedAt
      userId
      quiz
      startAt
      endAt
      width
      height
    }
  }
`;

export const GQL_REORDER_COURSE_MODULE = gql`
  mutation reorderCourseModule($input: ReorderCourseInput) {
    reorderCourseModule(input: $input) {
      id
      courseSectionId
      coachingUrl
      content {
        files {
          id
          filename
          size
          mimetype
          createdAt
        }
        file {
          id
          filename
          size
          mimetype
          createdAt
        }
        id
        createdAt
      }
      createdAt
      description
      embedUrl
      isPreview
      isContentNotProtected
      isVisible
      link
      status
      name
      paymentLinkId
      position
      type
      updatedAt
      userId
      quiz
      startAt
      endAt
    }
  }
`;

export const GQL_DELETE_AND_REORDER_COURSE_MODULE = gql`
  mutation deleteAndReorderCourseModule($id: ID, $paymentLinkId: ID, $courseSectionId: ID) {
    deleteAndReorderCourseModule(id: $id, paymentLinkId: $paymentLinkId, courseSectionId: $courseSectionId) {
      id
      courseSectionId
      coachingUrl
      content {
        files {
          id
          filename
          size
          mimetype
          createdAt
        }
        file {
          id
          filename
          size
          mimetype
          createdAt
        }
        id
        createdAt
      }
      createdAt
      description
      embedUrl
      isPreview
      isContentNotProtected
      isVisible
      link
      status
      name
      paymentLinkId
      position
      type
      updatedAt
      userId
      quiz
      startAt
      endAt
    }
  }
`;

export const INSERT_MODULE = gql`
  mutation insertCourseModule($input: CourseModuleInput) {
    insertCourseModule(input: $input) {
      id
    }
  }
`;

export const EDIT_MODULE = gql`
  mutation editCourseModule($input: CourseModuleInput) {
    editCourseModule(input: $input) {
      id
    }
  }
`;
