import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
  FormFeedback,
  Row,
  Col
} from 'reactstrap';
import DatePicker from '../date-time-picker';
import { GQL_PAYMENTLINK_CREATE_BY_SLUG, GQL_PAYMENTLINK_UPDATE_BY_SLUG } from '../../gqls';
import {
  validator,
  paymentLinkUrl,
  sanitizeDOM,
  slug,
  REGEX_SLUG,
  paymentTypeOptions,
} from '../../utils';
import Icon from '../icon';
import { updatePaymentLinks } from '../../actions/payment-links';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import SubmitButton from '../submit-button';
import RichEditor, { getPlainText, getContentString, getEditorContent } from '../rich-text-editor';
import { EditorState } from 'draft-js';
import MultipleImageInput from "../multiple-image-input";

const ModalBodyCoaching = ({ isOpen = false, onClose, onSuccess, data }) => {
  const [insertPaymentLinkBySlug, { loading: insertLoading, data: newpl }] = useMutation(
    GQL_PAYMENTLINK_CREATE_BY_SLUG
  );
  const [editPaymentLinkBySlug, { loading: updateLoading, data: upl }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);
  const { register, handleSubmit, errors, control } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [date, setDate] = useState(data ? data.expiredAt : null);
  const [link, setLink] = useState(data ? data.link : '');
  const dispatch = useDispatch();
  const isUpdate = !data ? false : true;
  const [paymentType, setPaymentType] = useState(data?.paymentType || 'paid');
  const stateEdit = EditorState.createEmpty();
  const [editorState, setEditorState] = useState(stateEdit);
  const [description, setDescription] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [check, setCheck] = useState(true);
  const [invalidDescription, setInvalidDescription] = useState(false);

  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  

  if (!data) data = {};

  const setEditor = content => {
    setEditorState(content);
    setDescription(getContentString(content));
    setTextDescription(getPlainText(content));
  };

  useEffect(() => {
    if (textDescription.length <= 0 && !check) {
      setInvalidDescription(true);
    } else {
      setInvalidDescription(false);
    }
  }, [textDescription]);

  useEffect(() => {
    if (isUpdate) {
      const content = getEditorContent(data.description);
      setEditor(content);
    }
  }, [data]);

  const onSubmit = async values => {
    if (textDescription.length <= 0) {
      setCheck(false);
      setInvalidDescription(true);
      return;
    }

    if (values.paymentType === 'free') {
      values.amount = 0;
    } else if (values.paymentType === 'voluntary' && !values.amount) {
      values.amount = 0;
    } else {
      values.amount = parseFloat(values.amount);
    }

    if (values.crossoutPrice) values.crossoutPrice = parseFloat(values.crossoutPrice);
    else delete values.crossoutPrice

    values.limit = values.limit ? parseInt(values.limit) : null;
    if (date !== null) setDate(date);

    if (isUpdate) {
      values.id = data.id;
    }

    const paymentLinkObject = {
      ...values,
      description: sanitizeDOM(description),
      expiredAt: date,
      ...(multipleImageId && { multipleImageId }),
      ...(isRemoveAllProductImage && { multipleImageId: null })
    };

    try {
      if (isUpdate) {
        paymentLinkObject.status = 'active';
        await editPaymentLinkBySlug({
          variables: {
            input: paymentLinkObject
          }
        });
        dispatch(updatePaymentLinks(Math.random()));
      } else {
        paymentLinkObject.type = 'coaching';
        paymentLinkObject.link = slug(values.name);
        paymentLinkObject.status = 'active';
        await insertPaymentLinkBySlug({
          variables: {
            input: paymentLinkObject
          }
        });
        //segment tracking
        window.analytics.track('Create Coaching Event', values);
      }
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Coaching Event - ERROR', values);
    }
  };

  if (newpl && newpl.insertPaymentLinkBySlug) onSuccess(newpl.insertPaymentLinkBySlug.id);
  if (upl) onClose();

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Coaching / Mentoring</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Buat formulir booking untuk pelanggan memesan sesi pelatihan / coaching / mentoring / konsultasi 1-on-1
            dengan anda.
          </small>
        </p>
        <hr />
        {submitError && <Alert color="danger">Error! Silahkan coba lagi atau hubungi CS</Alert>}
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-link">
          <FormGroup>
            <Label for="name">Nama Sesi Coaching / Mentoring / Konsultasi*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={errors.name}
              defaultValue={data.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="eventUrl">
              Kalender Booking URL (Calendly, Cal.com, SavvyCal, atau YouCanBookMe)*
              <img src="/calendar-book-app.png" alt="calendarbookapp" height="20" />
              <img src="/cal-com-icon.png" alt="cal.com" height="15" />
            </Label>
            <Input
              type="text"
              name="eventUrl"
              id="eventUrl"
              placeholder="https://calendly.com/username/session1"
              invalid={errors.eventUrl}
              defaultValue={data.eventUrl}
              innerRef={register({
                required: validator.required,
                pattern: validator.url
              })}
            />
            <FormFeedback>{errors?.eventUrl?.message}</FormFeedback>
            <small>
              Pelanggan akan diarahkan untuk membooking sesi coaching melalui halaman kalender ini setelah membayar.
              Kalender booking akan diembed di halaman setelah pembayaran secara otomatis oleh Mayar.
            </small>
          </FormGroup>
          {isUpdate && (
            <FormGroup>
              <Label for="name">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={errors.link}
                defaultValue={data.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + paymentLinkUrl(link)}</small>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="paymentType">Tipe Pembayaran</Label>
            <Controller
              name="paymentType"
              control={control}
              defaultValue={paymentType}
              render={({ onChange, name, value: defaultPaymentType }) => (
                <Select
                  id={name}
                  name={name}
                  options={paymentTypeOptions}
                  defaultValue={paymentTypeOptions.find(obj => obj.value === defaultPaymentType)}
                  placeholder="Pilih Tipe Pembayaran"
                  styles={!!errors.paymentType ? CustomStylesInvalid : CustomStyles}
                  className={`${!!errors.paymentType ? 'is-invalid' : ''}`}
                  onChange={({ value }) => {
                    setPaymentType(value);
                    onChange(value);
                  }}
                />
              )}
            />
          </FormGroup>
          {paymentType === 'paid' && (
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label for="amount">Harga Per-sesi*</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Rp</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      invalid={errors.amount}
                      defaultValue={data.amount}
                      innerRef={register({
                        required: validator.required,
                        min: validator.min(1000),
                        max: validator.max(100000000)
                      })}
                    />
                    <FormFeedback>{errors.amount?.message}</FormFeedback>
                  </InputGroup>
                  <small>Booking ini menggunakan mata uang IDR(Rp)</small>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label for="crossoutPrice">Harga Coret (opsional)</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Rp</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="crossoutPrice"
                      id="crossoutPrice"
                      defaultValue={data?.crossoutPrice}
                      invalid={errors.crossoutPrice}
                      innerRef={register({
                        min: validator.min(1000),
                        max: validator.max(100000000)
                      })}
                    />
                    <FormFeedback>{errors.crossoutPrice?.message}</FormFeedback>
                    <small>Harga coret harus lebih besar dari harga utama.</small>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          )}
          {paymentType === 'voluntary' && (
            <FormGroup>
              <Label for="amount">Minimal Harga</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Rp</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  name="amount"
                  id="amount"
                  invalid={errors.amount}
                  placeholder="0+"
                  defaultValue={data.amount}
                  innerRef={register({
                    // required: validator.required,
                    min: validator.min(0),
                    max: validator.max(100000000)
                  })}
                />
                <FormFeedback>{errors.amount?.message}</FormFeedback>
              </InputGroup>
              <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
            </FormGroup>
          )}

          <MultipleImageInput productName={data.name} setMultipleImageId={setMultipleImageId} setIsRemoveAllProductImage={setIsRemoveAllProductImage} multipleImage={data.multipleImage} onLoading={setDisableSubmitButton} />

          <FormGroup>
            <Label for="description">Deskripsi / Catatan*</Label>
            <RichEditor onEditorStateChange={setEditor} editorState={editorState} invalidState={invalidDescription} />
            <FormFeedback className={`${invalidDescription ? 'd-block' : ''}`}>Wajib diisi</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="startAt">Waktu Mulai Penjualan</Label>
            <div className="mb-1">
              <Controller
                name="startAt"
                control={control}
                defaultValue={data.startAt || null}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih Tanggal Mulai"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className={`rui-datetimepicker form-control w-auto mb-1 ${!!errors.startAt ? 'is-invalid' : ''}`}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <FormFeedback className="d-block">{errors.startAt?.message}</FormFeedback>
            <small>
              Kami akan membuka link pembayaran pada tanggal dan waktu yang anda pilih. Opsional, kosongkan untuk
              langsung membuka penjualan
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="kadaluarsa4">Tanggal Kadaluarsa</Label>
            <div className="mb-1">
              <DatePicker
                placeholderText="Pilih tanggal atau kosongkan"
                dateFormat="yyyy/MM/dd"
                selected={date ? new Date(date) : null}
                minDate={new Date()}
                className="rui-datetimepicker form-control w-auto"
                onChange={d => setDate(d)}
              />
            </div>
            <small>Kami akan menutup link pembayaran setelah tanggal ini (opsional)</small>
          </FormGroup>
          <FormGroup>
            <Label for="limit">Maksimum Jumlah Pembayaran (Kuota / Qty)</Label>
            <Input
              type="number"
              name="limit"
              id="limit"
              invalid={errors.limit}
              defaultValue={data.limit}
              innerRef={register()}
            />
            <FormFeedback>{errors?.limit?.message}</FormFeedback>
            <small>
              Kami akan menutup link pembayaran setelah melewati batas jumlah maksimal. Kosongkan untuk tanpa limit
              jumlah (unlimited)
            </small>
            {/* </>
            )} */}
          </FormGroup>
          <FormGroup>
            <Label for="notes">Instruksi</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={errors.notes}
              defaultValue={data.notes}
              innerRef={register({
                required: validator.required
              })}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Instruksi adalah catatan yang akan dikirimkan ke pendaftar setelah melakukan pendaftaran/membayar. Anda
              bisa memasukkan instruksi masuk ke zoom, passwordnya, formulir google, kontak cs, syarat ketentuan dll
              disini.
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="terms">Syarat dan Ketentuan</Label>
            <Input
              type="textarea"
              name="terms"
              id="terms"
              invalid={errors.terms}
              defaultValue={data.terms}
              innerRef={register()}
            />
            <FormFeedback>{errors.terms?.message}</FormFeedback>
          </FormGroup>
          <hr />
          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            text={`${isUpdate ? 'Update' : 'Buat'} Coaching Event`}
            loading
            isDisabled={disableSubmitButton}
            isLoading={insertLoading || updateLoading}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalBodyCoaching;
