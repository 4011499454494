import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { EditorState } from 'draft-js';
import { useForm, Controller } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Row,
  Col
} from 'reactstrap';
import {
  GQL_PAYMENTLINK_CREATE_BY_SLUG,
  GQL_PAYMENTLINK_UPDATE_BY_SLUG,
  GQL_ACTIVE_PAYMENT_LINK_GET,
} from '../../gqls';
import {
  validator,
  sanitizeDOM,
  bundlingDetailUrl,
  REGEX_SLUG,
  slug,
  createProductListOptions,
  productOptionsHeader,
  parseJSON
} from '../../utils';
import { updatePaymentLinks } from '../../actions/payment-links';
import { CustomStyles, CustomStylesInvalid } from '../custom-styles/react-select-styles';
import Icon from '../icon';
import DatePicker from '../date-time-picker';
import SubmitButton from '../submit-button';
import MayarAlert from '../alert/alert';
import RichEditor, { getPlainText, getContentString, getEditorContent } from '../rich-text-editor';
import useSearchDebounce from '../../hooks/useSearchDebounce';
import MultipleImageInput from "../multiple-image-input";

const ModalBundle = ({ isOpen = false, onClose, onSuccess, data = {}, isUpdate }) => {
  if (!isOpen) return null;
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, setValue } = useForm();
  const stateEdit = EditorState.createEmpty();
  const [submitError, setSubmitError] = useState(false);
  const [link, setLink] = useState(data?.link || '');
  const [editorState, setEditorState] = useState(stateEdit);
  const [listProduct, setListProduct] = useState([]);
  const [selectProducts, setSelectProducts] = useState(null);
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useSearchDebounce(searchTerm, 400);

  const [multipleImageId, setMultipleImageId] = useState('');
  const [isRemoveAllProductImage, setIsRemoveAllProductImage] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const onEditorStateChange = content => {
    setEditorState(content);

    register('description');
    setValue('description', sanitizeDOM(getContentString(content)));
    register('textDescription');
    setValue('textDescription', getPlainText(content));
  };

  const [insertPaymentLinkBySlug, { loading: insertLoading }] = useMutation(GQL_PAYMENTLINK_CREATE_BY_SLUG);
  const [editPaymentLinkBySlug, { loading: updateLoading }] = useMutation(GQL_PAYMENTLINK_UPDATE_BY_SLUG);

  const [getPaymentLinks, { data: paymentLinks, loading: paymentLinksLoading }] = useLazyQuery(
    GQL_ACTIVE_PAYMENT_LINK_GET
  );
  const paramPaymentLinksSearch = {
    limit: 10,
    search: {
      status: [
        { operator: 'not', value: 'closed' },
        { operator: 'not', value: 'archived' },
        { operator: 'not', value: 'paid' }
      ],
      type: [
        { operator: 'not', value: 'payment_request' },
        { operator: 'not', value: 'membership_payment' },
        { operator: 'not', value: 'fundraising' },
        { operator: 'not', value: 'physical_product' },
        { operator: 'not', value: 'event' },
        { operator: 'not', value: 'bundling' },
        { operator: 'not', value: 'writing' },
        { operator: 'not', value: 'membership' }
      ]
    },
    sortField: 'createdAt',
    sortDirection: 'DESC'
  };

  const isLoading = insertLoading || updateLoading;
  useEffect(() => {
    getPaymentLinks({
      variables: paramPaymentLinksSearch
    });
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (searchTerm) {
        paramPaymentLinksSearch.search.name = [{ operator: 'like', value: `%${searchTerm}%` }];
      }
      getPaymentLinks({
        variables: paramPaymentLinksSearch,
        fetchPolicy: 'network-only'
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const headerList = productOptionsHeader.filter(item => !['physical_product', 'event', 'membership'].includes(item.value));
    setListProduct(createProductListOptions(paymentLinks?.getPaymentLinkFilteredList, headerList));
  }, [paymentLinks]);

  useEffect(() => {
    if (isUpdate) {
      const content = getEditorContent(data.description);
      onEditorStateChange(content);

      const activeProducts = data.bundlingPaymentLinks?.filter(({ status }) => status === 'ENABLED');
      if (activeProducts?.length > 0) {
        setSelectProducts(
          activeProducts.map(item => ({
            label: item.paymentLink.name,
            type: item.paymentLink.type,
            value: item.paymentLink.id,
            variant: item.paymentLink.variant,
            variantId: item.variantId
          }))
        );
      }
    }
  }, [isUpdate, data]);

  const onSubmit = async values => {
    try {
      const variables = {
        input: {
          ...values,
          ...(multipleImageId && { multipleImageId }),
          ...(isRemoveAllProductImage && { multipleImageId: null }),
          amount: parseFloat(values.amount),
          description: sanitizeDOM(values.description),
          status: 'active',
          type: 'bundling',
          bundlingProductType: 'payment_link',
          ...(!isUpdate && { link: slug(values.name) }),
          ...(isUpdate && { id: data.id }),
          bundling: values.bundling.map(item => ({
            paymentLinkId: item.value,
            variantId: values[`variant-${item.value}`] || null
          }))
        }
      };

      values.bundling.forEach(item => {
        delete variables.input[`variant-${item.value}`];
      });

      delete variables.input.textDescription;
      delete variables.input.limit;

      if (values.limit) {
        variables.input.limit = isUpdate ? parseInt(values.limit) + parseInt(data.limit || 0) : parseInt(values.limit);
      }

      if (isUpdate) {
        await editPaymentLinkBySlug({ variables });
        dispatch(updatePaymentLinks(Math.random()));
      } else {
        const res = await insertPaymentLinkBySlug({ variables });

        if (res.data?.insertPaymentLinkBySlug?.id) {
          onSuccess(res.data.insertPaymentLinkBySlug.id);
        }
      }

      //segment tracking
      window.analytics.track('Create Bundling', values);
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Bundling - ERROR', values);
    }
  };

  return (
    <Modal isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">{isUpdate ? 'Edit' : 'Buat'} Bundling</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>Jual paket bundling produk anda dengan mudah</small>
        </p>
        <hr />
        <MayarAlert isError={submitError} className="mb-5" />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="name">Nama Bundling*</Label>
            <Input
              type="text"
              name="name"
              id="name"
              invalid={!!errors.name}
              defaultValue={data.name}
              innerRef={register({
                required: validator.required,
                minLength: validator.minLength(5)
              })}
            />
            <FormFeedback>{errors.name?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="bundling">Pilih Produk Bundling*</Label>
            <Controller
              name="bundling"
              id="bundling"
              control={control}
              defaultValue={selectProducts}
              rules={{ required: { value: true, message: 'Wajib diisi' } }}
              render={({ onChange, value }) => (
                <Select
                  inputId="bundling"
                  value={value}
                  onInputChange={setSearchTerm}
                  placeholder="Ketik Nama Produk..."
                  styles={!!errors.bundling ? CustomStylesInvalid : CustomStyles}
                  className={`${!!errors.bundling ? 'is-invalid' : ''}`}
                  onChange={v => {
                    onChange(v?.length > 0 && v?.length < 51 ? v : null);
                    setSelectProducts(v?.length > 0 && v?.length < 51 ? v : null);
                  }}
                  options={value?.length === 50 ? [] : listProduct}
                  noOptionsMessage={() =>
                    value?.length === 50 ? "You've reached the max options value" : 'No options available'
                  }
                  isMulti
                  isClearable
                  isLoading={paymentLinksLoading}
                />
              )}
            />
            <FormFeedback>{errors.bundling?.message}</FormFeedback>
          </FormGroup>
          {selectProducts?.filter(item => item.variant)?.length ? (
            <FormGroup>
              <Label for="variant"> Pilih Varian Produk*</Label>
              {selectProducts
                ?.filter(item => item.variant)
                .map((item, index) => {
                  if (!item.variant) return null;
                  const variants = parseJSON(item.variant, []);

                  return (
                    <Row
                      key={item.value}
                      className={index === selectProducts.filter(i => i.variant).length - 1 ? '' : 'mb-5'}
                    >
                      <Col>
                        <Label htmlFor={`variant-${item.value}`}>{item.label}</Label>
                      </Col>
                      <Col>
                        <Input
                          type="select"
                          innerRef={register({ required: validator.required })}
                          name={`variant-${item.value}`}
                          id={`variant-${item.value}`}
                          invalid={!!errors[`variant-${item.value}`]}
                          defaultValue={item.variantId}
                        >
                          <option value="">Pilih Varian</option>
                          {variants.filter(variant => variant.status === "ACTIVE")?.map(variant => (
                            <option key={variant.id} value={variant.id}>
                              {variant.name}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  );
                })}

              {selectProducts?.some(item => !!errors[`variant-${item.value}`]) ? (
                <div className="is-invalid"></div>
              ) : (
                ''
              )}
              <FormFeedback>Wajib diisi</FormFeedback>
            </FormGroup>
          ) : null}
          {isUpdate && (
            <FormGroup>
              <Label for="name">Slug*</Label>
              <Input
                type="text"
                name="link"
                id="link"
                invalid={!!errors.link}
                defaultValue={data.link}
                onChange={e => setLink(e.target.value)}
                innerRef={register({
                  required: validator.required,
                  pattern: validator.pattern(REGEX_SLUG)
                })}
              />
              <FormFeedback>{errors.link?.message}</FormFeedback>
              <small>{'contoh : ' + bundlingDetailUrl(link)}</small>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="amount">Harga*</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Rp</InputGroupText>
              </InputGroupAddon>
              <Input
                type="number"
                name="amount"
                id="amount"
                invalid={!!errors.amount}
                defaultValue={data.amount}
                innerRef={register({
                  required: validator.required,
                  min: validator.min(1000),
                  max: validator.max(100000000)
                })}
              />
              <FormFeedback>{errors.amount?.message}</FormFeedback>
            </InputGroup>
            <small>Penagihan ini menggunakan mata uang IDR (Rupiah)</small>
          </FormGroup>

          <MultipleImageInput productName={data.name} setMultipleImageId={setMultipleImageId} setIsRemoveAllProductImage={setIsRemoveAllProductImage} multipleImage={data.multipleImage} onLoading={setDisableSubmitButton} />

          <FormGroup>
            <Label for="textDescription">Deskripsi*</Label>
            <Controller
              name="textDescription"
              id="textDescription"
              control={control}
              defaultValue=""
              rules={{ required: { value: true, message: 'Wajib diisi' }, minLength: validator.minLength(5) }}
              render={({ onChange }) => (
                <RichEditor
                  onEditorStateChange={content => {
                    onChange(getPlainText(content));
                    onEditorStateChange(content);
                  }}
                  editorState={editorState}
                  invalidState={!!errors.textDescription}
                />
              )}
            />
            <FormFeedback className={`${!!errors.textDescription ? 'd-block' : ''}`}>
              {errors.textDescription?.message}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="expiredAt">Tanggal Kadaluarsa</Label>
            <div className="mb-1">
              <Controller
                name="expiredAt"
                control={control}
                defaultValue={data.expiredAt}
                render={({ onChange, name, value }) => (
                  <DatePicker
                    id={name}
                    placeholderText="Pilih tanggal atau kosongkan"
                    dateFormat="yyyy/MM/dd"
                    selected={value ? new Date(value) : null}
                    minDate={new Date()}
                    className="rui-datetimepicker form-control w-auto mb-1"
                    onChange={d => onChange(d)}
                  />
                )}
              />
            </div>
            <small>Kami akan menutup link pembayaran setelah tanggal ini (opsional)</small>
          </FormGroup>
          <FormGroup>
            <Label for="notes">Pesan setelah bayar / catatan</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              invalid={!!errors.notes}
              defaultValue={data.notes}
              innerRef={register()}
            />
            <FormFeedback>{errors.notes?.message}</FormFeedback>
            <small>
              Pesan yang akan dilihat oleh pendaftar/pembeli setelah melakukan pendaftaran/membayar (opsional), masukkan
              ucapan terima kasih, instruksi join grup wa, atau pesan lainnya disini.{' '}
            </small>
          </FormGroup>
          {isUpdate ? (
            <FormGroup>
              <Row>
                <FormGroup className="col-6">
                  <Label for="limit">Kuota Sebelumnya</Label>
                  <Input type="number" placeholder={!data.limit ? 'Tidak Terbatas' : Number(data.limit)} disabled />
                </FormGroup>
                <FormGroup className="col-6 pl-0">
                  <Label for="limit">Tambah Kuota</Label>
                  <Input
                    type="number"
                    name="limit"
                    id="limit"
                    className="col-6 mw-100"
                    invalid={!!errors.limit}
                    innerRef={register({
                      min: validator.min(1),
                      max: validator.max(10_000_000)
                    })}
                  />
                  <FormFeedback>{errors.limit?.message}</FormFeedback>
                </FormGroup>
              </Row>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label for="limit">Maksimum Jumlah Pembayaran (Kuota / Qty)</Label>
              <Input
                type="number"
                name="limit"
                id="limit"
                invalid={!!errors.limit}
                defaultValue={data.limit}
                innerRef={register()}
              />
              <FormFeedback>{errors.limit?.message}</FormFeedback>
              <small>
                Kami akan menutup link pembayaran setelah melewati batas jumlah maksimal. Kosongkan untuk tanpa limit
                jumlah (unlimited)
              </small>
            </FormGroup>
          )}
          <FormGroup>
            <Label for="redirectUrl">Redirect URL</Label>
            <Input
              type="text"
              name="redirectUrl"
              id="redirectUrl"
              placeholder="https://websitesaya.com/pembayaran-sukses"
              invalid={!!errors.redirectUrl}
              defaultValue={data.redirectUrl}
              innerRef={register({ pattern: validator.url })}
            />
            <FormFeedback>{errors.redirectUrl?.message}</FormFeedback>
            <small>Jika diisi, Kami akan membawa pelanggan ke halaman ini setelah sukses membayar</small>
          </FormGroup>
          <SubmitButton
            size="lg"
            block
            color="brand"
            type="submit"
            className="text-center"
            text={`${isUpdate ? 'Update' : 'Buat'} Bundling`}
            loading
            isLoading={isLoading}
            isDisabled={disableSubmitButton}
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalBundle;
