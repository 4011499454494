/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import _, { debounce } from 'lodash';
import { useMutation, useApolloClient } from '@apollo/client';
import {
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
  Modal,
  FormFeedback,
  Row,
  Col
} from 'reactstrap';

import { GQL_PAYMENTLINK_CREATE, GQL_CREATE_PAYMENTTRANSACTION, GQL_CUSTOMER_SELECT } from '../../gqls';
import { formatRp, validator } from '../../utils';
import { addToast } from '../../actions';
import { CustomStyles } from '../custom-styles/react-select-styles';
import Icon from '../icon';
import MayarAlert from '../alert/alert';
import SubmitButton from '../submit-button';
import DatePicker from '../date-time-picker';

const gparam = {
  pageSize: 1000,
  page: 1,
  sortDirection: 'ASC',
  sortField: 'name',
  search: {
    mobile: [{ operator: 'not', value: '' }, { operator: 'notEmpty' }]
  }
};

const ModalBodyInvoice = ({ onSuccess, onClose, isOpen }) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { register, handleSubmit, errors, control } = useForm();
  const [submitError, setSubmitError] = useState(false);
  const [date, setDate] = useState(null);
  const [subtotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [discountType, setDiscountType] = useState('PERCENTAGE');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [invoiceList, setInvoiceList] = useState([
    {
      description: '',
      quantity: '',
      rate: ''
    }
  ]);

  const [insertPaymentLink, { loading }] = useMutation(GQL_PAYMENTLINK_CREATE);
  const [createPaymentTransaction, { loading: loadingPLT }] = useMutation(GQL_CREATE_PAYMENTTRANSACTION);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...invoiceList];
    list[index][name] = name === 'rate' || name === 'quantity' ? Number(value) : value;
    setInvoiceList(list);
  };

  const handleRemoveClick = index => {
    const list = [...invoiceList];
    list.splice(index, 1);
    setInvoiceList(list);
  };

  const handleAddClick = () => {
    setInvoiceList([...invoiceList, { description: '', quantity: '', rate: '' }]);
  };

  const mapOptionsToValues = options =>
    options.map(option => ({
      value: option.id,
      label: option.name + ' (' + option.email + ')'
    }));

  const _loadSuggestions = (keyword, callback) => {
    if (!keyword || keyword.length < 3) {
      return callback([]);
    }

    gparam.searchAny = {
      email: [{ operator: 'like', value: keyword + '%' }],
      name: [{ operator: 'like', value: '%' + keyword + '%' }]
    };

    client
      .query({
        query: GQL_CUSTOMER_SELECT,
        variables: gparam,
        context: { queryDeduplication: false }
      })
      .then(resp => callback(mapOptionsToValues(resp.data.getCustomerFilteredList)));
  };
  const loadSuggestions = debounce(_loadSuggestions, 500);

  useEffect(() => {
    let subTotal = 0;

    invoiceList.forEach(productLine => {
      const quantityNumber = parseFloat(productLine.quantity);
      const rateNumber = parseFloat(productLine.rate);
      const amount = quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

      subTotal += amount;
    });

    setDiscount(discountType === 'PERCENTAGE' ? Math.round((subTotal * discountAmount) / 100) : discountAmount);
    setSubTotal(subTotal);
  }, [invoiceList, discountAmount, discountType]);

  const sendMail = async (paymentLinkId, customerEmail) => {
    try {
      await axios.post(`${process.env.REACT_APP_PROXY}/mail-new-invoice`, { id: paymentLinkId });
      dispatch(
        addToast({
          title: 'Email terkirim',
          content: 'Kami telah mengirimkan email penagihan ini kepada ' + customerEmail,
          duration: 3000
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          title: 'Email gagal dikirim',
          duration: 3000,
          color: 'danger'
        })
      );
    }
  };

  const onSubmit = async values => {
    if (date !== null) setDate(date);
    const totalTax = subtotal * tax;
    let defaultDiscounts = null;

    if (values.discountAmount > 0) {
      defaultDiscounts = JSON.stringify([{
        description: values.discountDescription,
        amount: values.discountAmount,
        type: values.discountType
      }]);
    }

    try {
      const totalAmount = subtotal + totalTax / 100 - discount;
      const resPL = await insertPaymentLink({
        variables: {
          input: {
            customerId: values.customerId.value,
            expiredAt: date,
            description: values.memo,
            amount: parseFloat(totalAmount),
            link: Math.random().toString(36).substr(2, 12),
            items: JSON.stringify(invoiceList),
            tax: parseFloat(tax),
            notes: values.description2,
            type: 'invoice',
            status: 'unpaid',
            name: 'INVOICE',
            discounts: defaultDiscounts
          }
        }
      });
      const paymentLink = _.get(resPL, 'data.insertPaymentLink', {});
      const { customer } = paymentLink || {};

      const resPLT = await createPaymentTransaction({
        variables: {
          input: {
            email: customer.email,
            mobile: customer.mobile,
            name: customer.name,
            paymentLinkId: paymentLink?.id
          }
        }
      });
      const paymentLinkTransaction = _.get(resPLT, 'data.createPaymentTransaction', {});
      if (!paymentLinkTransaction?.id) throw new Error('Payment Link Transaction not created');
      if (values.sendNotif) sendMail(paymentLink?.id, values?.customerId?.label || paymentLink?.customerId);
      onSuccess(paymentLink?.id);
      //segment tracking
      window.analytics.track('Create Invoice', values);
    } catch (error) {
      setSubmitError(true);
      window.analytics.track('Create Invoice - ERROR', values);
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} fade>
      <div className="modal-header">
        <h5 className="modal-title h2">Buat Invoice</h5>
        <Button className="close" color="" onClick={onClose}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        <p>
          <small>
            Kirim Invoice ke pelanggan anda melalui email disertai pdf invoice. Dilengkapi dengan link pembayaran untuk
            membayar invoice dengan menggunakan berbagai metode pembayaran online. Mayar secara otomatis hanya
            mengaktifkan pembayaran transfer bank untuk invoice dengan nominal diatas 50juta untuk memberikan harga
            terbaik.
          </small>
        </p>
        <hr />
        <MayarAlert isError={submitError}></MayarAlert>
        <Form onSubmit={handleSubmit(onSubmit)} id="create-payment-request">
          <FormGroup>
            <Label for="pelanggan2">Pelanggan*</Label>
            <Controller
              as={
                <AsyncSelect
                  className={errors.customerId ? 'is-invalid' : ''}
                  loadOptions={loadSuggestions}
                  defaultOptions
                  escapeClearsValue={true}
                  openMenuOnClick={false}
                  isSearchable
                  isClearable={true}
                  placeholder="Cari pelanggan... (ketik minimal 3 huruf awal)"
                  cacheOptions={false}
                  styles={CustomStyles}
                />
              }
              name="customerId"
              control={control}
              defaultValue={''}
              rules={{ required: validator.required }}
            />
            <FormFeedback>{errors.customerId?.message}</FormFeedback>
            <small>
              Kamu perlu membuat data pelanggan dahulu di <Link to="/customers">Halaman Pelanggan</Link>
            </small>
          </FormGroup>
          <FormGroup>
            <Label for="dueDate">Tanggal Jatuh Tempo*</Label>
            <div className="mb-1">
              <DatePicker
                required
                placeholderText="Pilih tanggal atau kosongkan"
                dateFormat="yyyy/MM/dd"
                selected={date ? new Date(date) : null}
                minDate={new Date()}
                className="rui-datetimepicker form-control w-auto"
                onChange={d => setDate(d)}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="amount">Items*</Label>
            {invoiceList.map((items, i) => (
              <Row className="mt-4" key={i}>
                <Col xs="5">
                  <Input
                    type="text"
                    name="description"
                    id="description"
                    value={items.description}
                    invalid={Boolean(errors.description)}
                    innerRef={register()}
                    onChange={e => handleInputChange(e, i)}
                    placeholder="Nama Item"
                  />
                </Col>
                <Col xs="2" className="pr-0 pl-0">
                  <Input
                    type="text"
                    name="quantity"
                    id="quantity"
                    value={items.quantity}
                    invalid={Boolean(errors.quantity)}
                    onChange={e => handleInputChange(e, i)}
                    innerRef={register({
                      required: validator.required
                    })}
                    placeholder="Qty"
                  />
                </Col>
                <Col xs="3">
                  <Input
                    type="text"
                    name="rate"
                    id="rate"
                    value={items?.rate}
                    invalid={Boolean(errors.rate)}
                    onChange={e => handleInputChange(e, i)}
                    innerRef={register({
                      required: validator.required,
                      min: validator.min(1000),
                      max: validator.max(100000000)
                    })}
                    placeholder="Harga (Rp)"
                  />
                </Col>

                <Col xs="1" className="pl-0">
                  <Button outline size="md" color="danger" onClick={() => handleRemoveClick(i)}>
                    <Icon className="text-danger" name="trash" />
                  </Button>
                </Col>
              </Row>
            ))}

            <Row className="mt-10">
              <Col xs="12">
                <Button color="primary" outline onClick={handleAddClick}>
                  + Tambah Item
                </Button>
              </Col>
            </Row>
            <hr />
            <Label for="discount">Diskon</Label>
            <Row className='mb-10'>
              <Col xs="6">
                <Input
                  type="text"
                  name="discountDescription"
                  id="discountDescription"
                  invalid={Boolean(errors.discountDescription)}
                  innerRef={register()}
                  placeholder="Deskripsi"
                />
              </Col>
              <Col xs="2" className="pl-0 pr-0">
                <Input
                  type="select"
                  name="discountType"
                  id="discountType"
                  invalid={Boolean(errors.discountType)}
                  innerRef={register()}
                  placeholder="Tipe"
                  onChange={e => setDiscountType(e.target.value)}
                >
                  <option value="PERCENTAGE">%</option>
                  <option value="MONETARY">Rp</option>
                </Input>
              </Col>
              <Col xs="4">
                <Input
                  type="number"
                  name="discountAmount"
                  id="discountAmount"
                  invalid={Boolean(errors.discountAmount)}
                  innerRef={register()}
                  onChange={e => setDiscountAmount(e.target.value)}
                  placeholder="Jumlah"
                  min={0}
                  max={discountType === 'PERCENTAGE' ? 100 : subtotal}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6">Tax/PPN (opsional)</Col>
              <Col xs="2" className="pr-0 pl-0">
                <Input
                  type="number"
                  name="tax"
                  id="tax"
                  invalid={Boolean(errors.tax)}
                  onChange={e => setTax(e.target.value)}
                  innerRef={register()}
                  placeholder="% "
                />
              </Col>
              <Col xs="4">
                <Input
                  type="number"
                  name="taxAmount"
                  id="taxAmount"
                  invalid={Boolean(errors.taxAmount)}
                  innerRef={register()}
                  placeholder="- 40.000"
                  value={(subtotal * tax) / 100}
                  disabled
                />
              </Col>
            </Row>
            <hr />
            <Row className="mt-10">
              <Col xs="8">
                <h5>Total</h5>
              </Col>
              <Col xs="4">
                <h5>
                  <strong>{subtotal && !tax ? formatRp(subtotal - discount) : formatRp(subtotal + (subtotal * tax) / 100 - discount)}</strong>
                </h5>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label for="memo">Memo (opsional)</Label>
            <Input type="textarea" invalid={errors.memo} name="memo" id="memo" innerRef={register()} />
            <FormFeedback>{errors.memo?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="description2">Catatan Kaki (opsional)</Label>
            <Input
              type="textarea"
              invalid={errors.description2}
              name="description2"
              id="description2"
              innerRef={register()}
            />
            <FormFeedback>{errors.description2?.message}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <CustomInput
              id="formSwitch1"
              label="Kirim Invoice ke Email"
              name="sendNotif"
              type="switch"
              color="primary"
              innerRef={register()}
            />
          </FormGroup>
          <SubmitButton
            size="lg"
            block
            color="brand"
            className="text-center"
            type="submit"
            loading
            isLoading={loading || loadingPLT}
            text="Buat Invoice"
          />
        </Form>
      </ModalBody>
    </Modal>
  );
};

ModalBodyInvoice.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default ModalBodyInvoice;
